import React from 'react';
import { RouteComponentProps, useNavigate } from '@reach/router';
import './AuthPage.scss';
import LoginForm from '../LoginForm/LoginForm';
import SignupForm from '../SignupForm/SignupForm';
import API from '../../api';
import LocalStorageMap from '../../constants/localStorageMap';
import Routes from '../../constants/Routes';
import logo from '../../assets/images/logo_192.png';

function AuthPage(props: RouteComponentProps) {
    const [action, setAction] = React.useState('LOGIN');

    const navigate = useNavigate();

    React.useEffect(() => {
        document.title = `Pecunry | ${action === 'LOGIN' ? 'Login' : 'Signup'}`;
    }, [action]);

    const onSignInWithGoogle = async (response: any) => {
        const jwtToken = await API.googleOAuth(response.credential);
        if (jwtToken !== null) {
            localStorage.setItem(LocalStorageMap.JWT_TOKEN, jwtToken);
            navigate(Routes.HOME_PAGE);
        }
    };

    return (
        <section className="auth-page">
            <section className="auth-page__left-col">
                <div className="auth-page__left-col__box">
                    <h6>
                        The art is not in <span>making</span> money, but in{' '}
                        <span>keeping</span> it.
                    </h6>
                </div>
            </section>
            <section className="auth-page__right-col">
                <section className="auth-page__right-col__header">
                    <div className="auth-page__right-col__header__box">
                        <h6>
                            The art is not in <span>making</span> money, but in{' '}
                            <span>keeping</span> it.
                        </h6>
                    </div>
                </section>
                <div className="auth-page__right-col__heading">
                    <h2
                        className={
                            action === 'LOGIN'
                                ? 'auth-page__right-col__heading__action--active'
                                : 'auth-page__right-col__heading__action'
                        }
                        onClick={() => setAction('LOGIN')}
                    >
                        Login
                    </h2>
                    <h2>&nbsp;/&nbsp;</h2>
                    <h2
                        className={
                            action === 'SIGNUP'
                                ? 'auth-page__right-col__heading__action--active'
                                : 'auth-page__right-col__heading__action'
                        }
                        onClick={() => setAction('SIGNUP')}
                    >
                        Signup
                    </h2>
                </div>
                <div className="auth-page__right-col__form">
                    {action === 'LOGIN' ? (
                        <LoginForm
                            onSignup={() => setAction('SIGNUP')}
                            onLogin={() => navigate('/home')}
                            onSignInWithGoogle={onSignInWithGoogle}
                        />
                    ) : (
                        <SignupForm
                            onSignup={() => {}}
                            onLogin={() => setAction('LOGIN')}
                            onSignInWithGoogle={onSignInWithGoogle}
                        />
                    )}
                </div>
            </section>
            <div className="auth-page__center">
                <img
                    className="animate__animated animate__bounceIn"
                    src={logo}
                    alt="Pecunry logo"
                />
            </div>
        </section>
    );
}

export default AuthPage;
