import React from 'react';
import { HashLoader } from 'react-spinners';
import './OverlayLoader.scss';

function OverlayLoader() {
    return (
        <div className="overlay-loader">
            <div className="overlay-loader__loader">
                <div className="overlay-loader__loader__icon">
                    <HashLoader size={60} color={'#c78eb9'} loading />
                </div>
                <div className="overlay-loader__loader__text">loading</div>
            </div>
        </div>
    );
}

export default OverlayLoader;
