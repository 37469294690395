import React from 'react';
import './SignupForm.scss';
import GoogleLogin from '../GoogleLogin/GoogleLogin';
import { ISignupForm } from './SignupForm.interface';

function SignupForm(props: ISignupForm) {
    const [email, setEmail] = React.useState('');
    const [, setEmailError] = React.useState(' ');
    const [password, setPassword] = React.useState('');
    const [, setPasswordError] = React.useState(' ');
    const [passwordConfirm, setPasswordConfirm] = React.useState('');
    const [, setPasswordConfirmError] = React.useState(' ');

    const onSignup = (event: any) => {
        if (
            !/^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/.test(
                email
            )
        ) {
            setEmailError('Invalid email address.');
            return;
        }
        setEmailError('');
        if (
            !/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(
                password
            )
        ) {
            setPasswordError("Password doesn't meet all criterion.");
            return;
        }
        setPasswordError('');
        if (password !== passwordConfirm) {
            setPasswordConfirmError("Password confirmation doesn't match.");
            return;
        }
        setPasswordConfirmError('');
        props.onSignup({ email, password });
    };

    const onLogin = (event: any) => {
        event.preventDefault();
        props.onLogin();
    };

    return (
        <div className="signup-form">
            <label className="signup-form__label">Email ID:</label>
            <input
                className="signup-form__input"
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
            />
            <label className="signup-form__label">Password:</label>
            <input
                className="signup-form__input"
                type="password"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
            />
            <label className="signup-form__label">Confirm password:</label>
            <input
                className="signup-form__input"
                type="password"
                onChange={(e) => setPasswordConfirm(e.target.value)}
                value={passwordConfirm}
            />
            <button className="signup-form__button" onClick={onSignup}>
                SIGNUP
            </button>
            <a className="signup-form__label-link" href="#" onClick={onLogin}>
                Already registered? Login instead.
            </a>
            <hr className="signup-form__hr" />
            <div className="signup-form__google">
                <GoogleLogin
                    text="signup_with"
                    onComplete={props.onSignInWithGoogle}
                />
            </div>
        </div>
    );
}

export default SignupForm;
