import React, { useEffect, useState } from 'react';
import './EntityList.scss';
import { IEntityList } from './EntityList.interface';
import EntityListItem from '../EntityListItem/EntityListItem';

function EntityList(props: IEntityList) {
    const [total, setTotal] = useState(0);

    useEffect(() => {
        setTotal(
            props.items.reduce((acc, item) => {
                return acc + item.amount;
            }, 0)
        );
    }, [props.items]);

    return (
        <section className="entity-list">
            <div className="entity-list__header">
                <h5 className="entity-list__header__title">{props.title}</h5>
                <button
                    className="entity-list__header__button"
                    onClick={() => props.onAdd()}
                >
                    Add new
                </button>
            </div>
            <ul className="entity-list__list">
                {props.items.map((item, index) => (
                    <li key={index} className="entity-list__list__item">
                        <EntityListItem
                            name={item.name}
                            type={item.type}
                            amount={item.amount}
                            icon={item.icon}
                            onOpen={() => props.onUpdate(item)}
                        />
                    </li>
                ))}
            </ul>
            {props.items.length !== 0 && (
                <div className="entity-list__footer-note">
                    {props.showTotal && (
                        <div className="entity-list__footer-note__total">
                            <div className="entity-list__footer-note__total-title">
                                Total
                            </div>
                            <div className="entity-list__footer-note__total-amount">
                                {total}
                            </div>
                        </div>
                    )}
                    <div>Click an item to update or delete.</div>
                </div>
            )}
        </section>
    );
}

export default EntityList;
