import React from 'react';
import './AddOverlay.scss';
import { FaCheck, FaTimes } from 'react-icons/fa';
import API from '../../api';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentMonth } from '../../redux/selectors';
import { IAddOverlayProps } from './AddOverlay.interface';
import { SET_EMIS, SET_EXPENSES, SET_PAYMENTS } from '../../redux/actionTypes';
import { Category, Entity } from '../../models';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

function AddOverlay(props: IAddOverlayProps) {
    const [title, setTitle] = React.useState('');
    const [amount, setAmount] = React.useState('');
    const [category, setCategory] = React.useState(Category.ESSENTIAL);
    const [expenseType, setExpenseType] = React.useState(Entity.EXPENSE);

    const dispatch = useDispatch();

    const month = useSelector(getCurrentMonth);

    const onAdd = async () => {
        if (amount === '') {
            return;
        }
        try {
            if (props.type === Entity.EXPENSE) {
                const expenses = await API.addExpense(month, {
                    title,
                    category,
                    amount: parseInt(amount),
                    isEMI: expenseType === Entity.EMI,
                    isRepeatPayment: expenseType === Entity.PAYMENT
                });
                dispatch({ type: SET_EXPENSES, expenses });
            } else if (props.type === Entity.PAYMENT) {
                const payments = await API.addPayment({
                    title,
                    category,
                    amount: parseInt(amount)
                });
                dispatch({ type: SET_PAYMENTS, payments });
            } else if (props.type === Entity.EMI) {
                const emis = await API.addEMI({
                    title,
                    category,
                    amount: parseInt(amount)
                });
                dispatch({ type: SET_EMIS, emis });
            }
        } catch (e) {}
        props.onCancel();
    };

    return (
        <section className="add-overlay">
            <div className="add-overlay__add-expense">
                <h6 className="add-overlay__add-expense__heading">
                    {`Add ${props.type}`}
                </h6>
                <form className="add-overlay__add-expense__form">
                    <input
                        className="add-overlay__box-input"
                        value={title}
                        type="text"
                        placeholder="Title"
                        onChange={(e: any) => setTitle(e.target.value)}
                    />
                    <input
                        className="add-overlay__box-input"
                        value={amount}
                        type="number"
                        placeholder="Amount"
                        onChange={(e: any) => setAmount(e.target.value)}
                    />
                    <Dropdown
                        options={[
                            Category.ESSENTIAL,
                            Category.DISCRETIONARY,
                            Category.ENTERTAINMENT
                        ]}
                        value={Category.ESSENTIAL}
                        onChange={(selection) =>
                            setCategory(selection.value as Category)
                        }
                    />
                    {props.type === Entity.EXPENSE && (
                        <Dropdown
                            options={[
                                Entity.EXPENSE,
                                Entity.PAYMENT,
                                Entity.EMI
                            ]}
                            value={props.type}
                            onChange={(selection) =>
                                setExpenseType(selection.value as Entity)
                            }
                        />
                    )}
                    <div className="add-overlay__buttons-container">
                        <button
                            className="add-overlay__cancel-button"
                            type="button"
                            onClick={() => props.onCancel()}
                        >
                            <FaTimes color="#c0c0c0" />
                            <div>Cancel</div>
                        </button>
                        <button
                            className="add-overlay__save-button"
                            type="button"
                            onClick={() => onAdd()}
                        >
                            <div>Save</div>
                            <FaCheck color="#c0c0c0" />
                        </button>
                    </div>
                </form>
            </div>
        </section>
    );
}

export default AddOverlay;
