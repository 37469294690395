import { RouteComponentProps, Router } from '@reach/router';
import HomePage from '../HomePage/HomePage';
import SettingsPage from '../SettingsPage/SettingsPage';
import React from 'react';
import Drawer from '../Drawer/Drawer';
import './AppPage.scss';

function AppPage(props: RouteComponentProps) {
    return (
        <section className="app-page">
            <Drawer />
            <div className="app-page__body">
                <Router>
                    <HomePage path="home" />
                    <SettingsPage path="settings" />
                </Router>
            </div>
        </section>
    );
}

export default AppPage;
