export const SET_APP_STATE = 'SET_APP_STATE';

export const SET_MONTH = 'SET_MONTH';
export const SET_MONTH_DATA = 'SET_MONTH_DATA';
export const SET_MONTH_INCOME = 'SET_MONTH_INCOME';

export const SET_EXPENSES = 'SET_EXPENSES';
export const UPDATE_EXPENSE = 'UPDATE_EXPENSE';
export const DELETE_EXPENSE = 'DELETE_EXPENSE';

export const SET_PAYMENTS = 'SET_PAYMENTS';
export const UPDATE_PAYMENT = 'UPDATE_PAYMENT';
export const DELETE_PAYMENT = 'DELETE_PAYMENT';

export const SET_EMIS = 'SET_EMIS';
export const UPDATE_EMI = 'UPDATE_EMI';
export const DELETE_EMI = 'DELETE_EMI';

export const SET_ALL_SETTINGS = 'SET_ALL_SETTINGS';
export const UPDATE_DEFAULT_MONTHLY_INCOME = 'UPDATE_DEFAULT_MONTHLY_INCOME';
export const UPDATE_TOGGLE_SETTINGS = 'UPDATE_TOGGLE_SETTINGS';
