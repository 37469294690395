import { IStoreState } from './reducers';

export const getAppState = (store: IStoreState) => store.app.state;

export const getCurrentMonth = (store: IStoreState) => ({
    month: store.dashboard.currentMonth,
    year: store.dashboard.currentYear
});

export const getMonthData = (store: IStoreState) => store.dashboard.data.month;

export const getPayments = (store: IStoreState) =>
    store.dashboard.data.payments;

export const getEMIs = (store: IStoreState) => store.dashboard.data.emis;

export const getSettings = (store: IStoreState) => store.settings;
