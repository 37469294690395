import React from 'react';
import { IGoogleLogin } from './GoogleLogin.interface';

function GoogleLogin(props: IGoogleLogin) {
    const parentRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        if ((window as any).google) {
            (window as any).google.accounts.id.initialize({
                client_id:
                    '651737803865-mlt008lc4cntrfrejuovatqk14rnv06u.apps.googleusercontent.com',
                auto_select: true,
                cancel_on_tap_outside: false,
                context: 'signin',
                callback: props.onComplete
            });
        }
    }, [props.onComplete]);

    React.useEffect(() => {
        if (parentRef.current && (window as any).google) {
            if (parentRef.current.firstChild) {
                parentRef.current.removeChild(parentRef.current.firstChild);
            }

            (window as any).google.accounts.id.renderButton(parentRef.current, {
                text: props.text
            });
        }
    }, [props.text]);

    return <div ref={parentRef} />;
}

export default GoogleLogin;
