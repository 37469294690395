import React from 'react';
import './Drawer.scss';
import {
    FaDownload,
    FaUpload,
    FaInfoCircle,
    FaSignOutAlt,
    FaCog,
    FaHome,
    FaBars
} from 'react-icons/fa';
import { useLocation, useNavigate } from '@reach/router';
import classNames from 'classnames';
import LocalStorageMap from '../../constants/localStorageMap';
import Routes from '../../constants/Routes';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentMonth, getMonthData } from '../../redux/selectors';
import { formatMonthToString } from '../../utils/formatters';
import { SET_MONTH_DATA, SET_MONTH_INCOME } from '../../redux/actionTypes';
import API from '../../api';
import { generateAndSavePDF } from '../../utils/pdfGenerator';
import MonthPickerOverlay from '../MonthPickerOverlay/MonthPickerOverlay';
import FileSaver from 'file-saver';

function Drawer() {
    const location = useLocation();
    const navigate = useNavigate();

    const month = useSelector(getCurrentMonth);
    const monthData = useSelector(getMonthData);

    const dispatch = useDispatch();

    const [drawerExpanded, setDrawerExpanded] = React.useState(false);
    const [income, setIncome] = React.useState(
        monthData ? monthData.income : 0
    );
    const [incomeDisabled, setIncomeDisabled] = React.useState(false);
    const [showMonthPicker, setShowMonthPicker] = React.useState(false);
    const [exportDisabled, setExportDisabled] = React.useState(false);

    const onLogout = () => {
        localStorage.removeItem(LocalStorageMap.JWT_TOKEN);
        navigate(Routes.AUTH_PAGE);
    };

    const onIncomeChange = (event: any) => {
        setIncome(event.target.value);
    };

    const updateIncome = () => {
        if (monthData.income === income || `${income}` === '') {
            setIncome(monthData.income);
            return;
        }

        setIncomeDisabled(true);
        API.updateIncome(month, income).then(() => {
            dispatch({ type: SET_MONTH_INCOME, income });
            setIncomeDisabled(false);
        });
    };

    const exportData = async () => {
        if (exportDisabled) {
            return;
        }
        setExportDisabled(true);
        const jsonData = await API.requestDataExport();
        const blob = new Blob([JSON.stringify(jsonData)], {
            type: 'text/plain;charset=utf-8'
        });
        FileSaver.saveAs(blob, 'pecunry_backup.json');
        setExportDisabled(false);
    };

    React.useEffect(() => {
        setIncome(monthData.income);
        setIncomeDisabled(false);
    }, [monthData]);

    React.useEffect(() => {
        API.getMonthData(month).then((data) => {
            dispatch({ type: SET_MONTH_DATA, data });
        });
    }, [month.month, month.year]);

    return (
        <>
            <section
                className={classNames({
                    'drawer-overlay': true,
                    'drawer-overlay--expanded': drawerExpanded
                })}
                onClick={() => setDrawerExpanded(false)}
            />
            <section className="navbar">
                <FaBars
                    color="#c0c0c0"
                    size={20}
                    onClick={() => setDrawerExpanded(true)}
                />
                <button
                    className="navbar__month"
                    onClick={() => setShowMonthPicker(true)}
                >
                    {formatMonthToString(month)}
                </button>
            </section>
            <section
                className={classNames({
                    drawer: true,
                    'drawer--expanded': drawerExpanded
                })}
            >
                <div className="drawer__config">
                    <button
                        className="drawer__month"
                        onClick={() => setShowMonthPicker(true)}
                    >
                        {formatMonthToString(month)}
                    </button>
                    <input
                        className="drawer__box-button"
                        value={income}
                        type="number"
                        onChange={onIncomeChange}
                        onBlur={updateIncome}
                        disabled={incomeDisabled}
                    />
                    <button
                        className="drawer__box-button"
                        onClick={() => generateAndSavePDF(month, monthData)}
                    >
                        Generate PDF Report
                    </button>
                </div>
                <div className="drawer__options">
                    <button
                        className={classNames({
                            'drawer__icon-button': true,
                            'drawer__icon-button--active':
                                location.pathname === Routes.HOME_PAGE
                        })}
                        onClick={() => {
                            setDrawerExpanded(false);
                            navigate(Routes.HOME_PAGE);
                        }}
                    >
                        <FaHome color="#f7f6f6" />
                        <div>Home</div>
                        <span />
                    </button>
                    <hr className="drawer__hr" />
                    <button
                        className={classNames({
                            'drawer__icon-button': true,
                            'drawer__icon-button--active':
                                location.pathname === '/import'
                        })}
                    >
                        <FaUpload color="#f7f6f6" />
                        <div>Import data</div>
                        <span />
                    </button>
                    <button
                        className="drawer__icon-button"
                        onClick={exportData}
                    >
                        <FaDownload color="#f7f6f6" />
                        <div>Export data</div>
                        <span />
                    </button>
                    <hr className="drawer__hr" />
                    <button
                        className={classNames({
                            'drawer__icon-button': true,
                            'drawer__icon-button--active':
                                location.pathname === Routes.SETTINGS_PAGE
                        })}
                        onClick={() => {
                            setDrawerExpanded(false);
                            navigate(Routes.SETTINGS_PAGE);
                        }}
                    >
                        <FaCog color="#f7f6f6" />
                        <div>Settings</div>
                        <span />
                    </button>
                    <button
                        className={classNames({
                            'drawer__icon-button': true,
                            'drawer__icon-button--active':
                                location.pathname === '/about'
                        })}
                    >
                        <FaInfoCircle color="#f7f6f6" />
                        <div>About</div>
                        <span />
                    </button>
                    <button className="drawer__icon-button" onClick={onLogout}>
                        <FaSignOutAlt color="#f7f6f6" />
                        <div>Logout</div>
                        <span />
                    </button>
                </div>
                {showMonthPicker && (
                    <MonthPickerOverlay
                        onCancel={() => setShowMonthPicker(false)}
                    />
                )}
            </section>
        </>
    );
}

export default Drawer;
