import { Category, IMonth, IMonthData } from '../models';
import { formatMonthToString } from './formatters';
import { getPercent } from './calculators';
import { getUserInfoFromJWT } from './jwtHandler';

export function generateAndSavePDF(month: IMonth, monthData: IMonthData) {
    let totalExpense = 0;
    let totalEssential = 0;
    let totalDiscretionary = 0;
    let totalEntertainment = 0;

    monthData.expenses.forEach((expense) => {
        totalExpense += expense.amount;
        if (expense.category === Category.ESSENTIAL) {
            totalEssential += expense.amount;
        } else if (expense.category === Category.DISCRETIONARY) {
            totalDiscretionary += expense.amount;
        } else if (expense.category === Category.ENTERTAINMENT) {
            totalEntertainment += expense.amount;
        }
    });

    const totalRemaining =
        monthData.income - totalExpense < 0
            ? 0
            : monthData.income - totalExpense;
    const totalExpensePer = getPercent(totalExpense, monthData.income);
    const totalEssentialPer = getPercent(totalEssential, totalExpense);
    const totalDiscretionaryPer = getPercent(totalDiscretionary, totalExpense);
    const totalEntertainmentPer = getPercent(totalEntertainment, totalExpense);
    const totalRemainingPer = getPercent(totalRemaining, monthData.income);
    const htmlString = `
        <head>
            <title>Pecunry - Monthly report - ${formatMonthToString(
                month
            )}</title>
        </head>
        <body>
            <section style="font-family: serif; margin: 40px 40px 0 40px;">
                <style>
                    .table-collapse {
                        border-collapse: collapse;
                    }
            
                    .border1 {
                        border: 1px solid black;
                    }
            
                    .a-right {
                        text-align: right;
                    }
            
                    td {
                        padding: 10px;
                    }
                </style>
                <center>
                    <h2><u>Pecunry: Monthly expense report</u></h2>
                    <table class="table-collapse" cellspacing="0" style="width:100%;">
                        <tr>
                            <td style="width:10%;"></td>
                            <td class="border1" style="width:50%;">
                                <strong>User: </strong>${
                                    getUserInfoFromJWT()?.username
                                }
                            </td>
                            <td style="width:30%;" class="border1">
                                <strong>Month: </strong>${formatMonthToString(
                                    month
                                )}
                            </td>
                            <td style="width:10%;"></td>
                        </tr>
                    </table>
                </center>
                <br/>
                <table class="table-collapse" cellspacing="0" style="width:100%;">
                    <tr>
                        <td class="border1" style="width:30%;"><strong>Total income:</strong></td>
                        <td class="border1 a-right" style="width:20%;">${
                            monthData.income
                        } Rs.</td>
                        <td width="50%"></td>
                    </tr>
                    <tr>
                        <td class="border1" style="width:30%;"><strong>Total expense:</strong></td>
                        <td class="border1 a-right" style="width:20%;">${totalExpense} Rs.</td>
                        <td class="border1" width="50%">${totalExpensePer}% income used in expenses.</td>
                    </tr>
                    <tr>
                        <td class="border1" style="width:30%;"><strong>Remaining:</strong></td>
                        <td class="border1 a-right" style="width:20%;">${totalRemaining} Rs.</td>
                        <td class="border1" style="width:50%;">${totalRemainingPer}% saved.</td>
                    </tr>
                </table>
                <br/>
                ${
                    monthData.expenses.length === 0
                        ? ''
                        : `
                        <table class="table-collapse" cellspacing="0" style="width:100%;">
                            <tr>
                                <td class="border1" style="width:25%;"><strong>Total essential expenses</strong></td>
                                <td class="border1" style="width:25%;"><strong>Total discretionary expenses</strong></td>
                                <td class="border1" style="width:25%;"><strong>Total entertainment expenses</strong></td>
                            </tr>
                            <tr>
                                <td class="border1" style="width:25%;">${totalEssential} Rs.<br/><br/>(${totalEssentialPer}% of total expenses is spent on essential expenses.)</td>
                                <td class="border1" style="width:25%;">${totalDiscretionary} Rs.<br/><br/>(${totalDiscretionaryPer}% of total expenses is spent on discretionary expenses.)</td>
                                <td class="border1" style="width:25%;">${totalEntertainment} Rs.<br/><br/>(${totalEntertainmentPer}% of total expenses is spent on entertainment expenses.)</td>
                            </tr>
                        </table>
                        <br/>
                    `
                }
                <hr/>
                ${
                    monthData.expenses.length === 0
                        ? `
                        <center>
                            <h4>No expenses recorded in ${formatMonthToString(
                                month
                            )}.</h4>
                        </center>
                    `
                        : `
                        <h4><u>Expenses made in ${formatMonthToString(
                            month
                        )}:</u></h4>
                        <table style="border-collapse:collapse;width:100%;" cellspacing="0">
                            <tr>
                                <td style="border:1px solid black;text-align:right;width:10%;"><strong>#</strong></td>
                                <td class="border1" style="width:40%;"><strong>Description</strong></td>
                                <td class="border1" style="width:30%;"><strong>Category</strong></td>
                                <td class="border1" style="width:20%;"><strong>Amount</strong></td>
                            </tr>
                            ${monthData.expenses.reduce(
                                (acc, expense, index) => {
                                    return (
                                        acc +
                                        `
                                        <tr>
                                            <td style="border:1px solid black;text-align:right;width:10%;">${
                                                index + 1
                                            }</td>
                                            <td class="border1" style="width:40%;">${
                                                expense.title
                                            }</td>
                                            <td class="border1" style="width:30%;">${
                                                expense.category
                                            }</td>
                                            <td class="border1 a-right" style="width:20%;">${
                                                expense.amount
                                            } Rs.</td>
                                        </tr>
                                    `
                                    );
                                },
                                ''
                            )}
                            <tr>
                                <td style="witdh:10%;"></td>
                                <td style="witdh:40%;"></td>
                                <td class="border1 a-right" style="width:30%;"><strong>Total:</strong></td>
                                <td class="border1 a-right" style="width:20%;">${totalExpense} Rs.</td>
                            </tr>
                        </table>
                        <br/><br/>
                    `
                }
                <hr/>
                <br/>
                <center>
                    (We hope 'Pecunry' helped you in managing your expenses effectively.)
                    <br/><br/>
                    For any suggestions, bugs and problems feel free to contact us at: <a href="mailto:pecunry@gmail.com">pecunry@gmail.com</a>
                </center>
            </section>
        </body>
    `;

    const pdfWindow = window.open(
        '',
        `Pecunry - Monthly report - ${formatMonthToString(month)}`,
        `toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=no,width=770,height=800`
    );
    if (pdfWindow) {
        pdfWindow.document.write(htmlString);
        setTimeout(() => {
            pdfWindow.print();
        }, 1000);
    }
}
