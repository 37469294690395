import LocalStorageMap from '../constants/localStorageMap';
import jwt from 'jsonwebtoken';
import { ITokenInfo } from '../models';

export function getUserInfoFromJWT(): ITokenInfo | null {
    const jwtToken = localStorage.getItem(LocalStorageMap.JWT_TOKEN);
    if (!jwtToken) {
        return null;
    }

    return jwt.decode(jwtToken) as ITokenInfo;
}
