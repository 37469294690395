import React from 'react';
import MeterSet from '../MeterSet/MeterSet';
import EntityTabList from '../EntityTabList/EntityTabList';
import EntityList from '../EntityList/EntityList';
import './HomePage.scss';
import { RouteComponentProps } from '@reach/router';
import API from '../../api';
import { SET_EMIS, SET_PAYMENTS } from '../../redux/actionTypes';
import { useDispatch, useSelector } from 'react-redux';
import { getEMIs, getMonthData, getPayments } from '../../redux/selectors';
import AddOverlay from '../AddOverlay/AddOverlay';
import { Entity, EntityIcon, IEntityDB } from '../../models';
import { getMeterReadings } from '../../utils/calculators';
import UpdateOverlay from '../UpdateOverlay/UpdateOverlay';
import Toaster from '../../utils/toastHandler';

function HomePage(props: RouteComponentProps) {
    const [addType, setAddType] = React.useState<Entity | null>(null);
    const [updateType, setUpdateType] = React.useState<Entity | null>(null);
    const [updateEntity, setUpdateEntity] = React.useState<IEntityDB | null>(
        null
    );
    const [meterData, setMeterData] = React.useState([10, 0, 0]);

    const dispatch = useDispatch();

    const monthData = useSelector(getMonthData);
    const payments = useSelector(getPayments);
    const emis = useSelector(getEMIs);

    React.useEffect(() => {
        document.title = 'Pecunry | Home';

        API.getPayments().then((payments) => {
            dispatch({ type: SET_PAYMENTS, payments });
        });
        API.getEMIs().then((emis) => {
            dispatch({ type: SET_EMIS, emis });
        });
    }, []);

    React.useEffect(() => {
        const { readings, error } = getMeterReadings(monthData);
        setMeterData(readings);
        if (error) {
            Toaster.toast(getErrorMessage(error), true);
        }
    }, [monthData]);

    const getErrorMessage = (error: 'INVALID_INCOME' | 'MORE_THAN_100') => {
        if (error === 'INVALID_INCOME') {
            return 'Invalid monthly income';
        }
        return 'Expenses exceed monthly income';
    };

    const onEntityUpdate = (item: any, type: Entity) => {
        setUpdateType(type);
        setUpdateEntity({
            id: item.data,
            title: item.name,
            amount: item.amount,
            category: item.type
        });
    };

    return (
        <div className="home-page-container">
            <section className="home-page">
                <section className="home-page__left-col">
                    <MeterSet
                        savings={meterData[0]}
                        emi={meterData[1]}
                        expense={meterData[2]}
                    />
                    <div className="home-page__left-col__tabs">
                        <EntityTabList
                            tabs={[
                                {
                                    title: 'Payments',
                                    items: payments.map((payment) => ({
                                        name: payment.title,
                                        type: payment.category,
                                        amount: payment.amount,
                                        icon: EntityIcon.none,
                                        data: payment.id
                                    })),
                                    onAdd: () => setAddType(Entity.PAYMENT),
                                    onUpdate: (payment: any) =>
                                        onEntityUpdate(payment, Entity.PAYMENT)
                                },
                                {
                                    title: 'EMIs',
                                    items: emis.map((emi) => ({
                                        name: emi.title,
                                        type: emi.category,
                                        amount: emi.amount,
                                        icon: EntityIcon.none,
                                        data: emi.id
                                    })),
                                    onAdd: () => setAddType(Entity.EMI),
                                    onUpdate: (emi: any) =>
                                        onEntityUpdate(emi, Entity.EMI)
                                }
                            ]}
                        />
                    </div>
                </section>
                <section className="home-page__right-col">
                    <EntityList
                        title="Expenses"
                        items={monthData.expenses.map((expense) => ({
                            name: expense.title,
                            type: expense.category,
                            amount: expense.amount,
                            icon: EntityIcon.none,
                            data: expense.id
                        }))}
                        showTotal
                        onAdd={() => setAddType(Entity.EXPENSE)}
                        onUpdate={(expense: any) =>
                            onEntityUpdate(expense, Entity.EXPENSE)
                        }
                    />
                </section>
            </section>
            {addType !== null && (
                <AddOverlay onCancel={() => setAddType(null)} type={addType} />
            )}
            {updateEntity !== null && updateType !== null && (
                <UpdateOverlay
                    onCancel={() => setUpdateEntity(null)}
                    entity={updateEntity}
                    type={updateType}
                />
            )}
        </div>
    );
}

export default HomePage;
