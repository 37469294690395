import { MONTHS } from '../constants/static';
import { IMonth } from '../models';

export function formatMonthToString(month: IMonth, separator = ' '): string {
    return `${MONTHS[month.month]}${separator}${month.year}`;
}

export function formatStringToMonth(
    monthString: string,
    separator = ' '
): IMonth {
    return {
        month: MONTHS.indexOf(monthString.split(separator)[0]),
        year: parseInt(monthString.split(separator)[1])
    };
}
