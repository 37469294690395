import React from 'react';
import './EntityTabList.scss';
import { IEntityTabList } from './EntityTabList.interface';
import EntityListItem from '../EntityListItem/EntityListItem';

function EntityTabList(props: IEntityTabList) {
    const [activeTab, setActiveTab] = React.useState(0);

    return (
        <section className="entity-tab-list">
            <div className="entity-tab-list__header">
                <div className="entity-tab-list__header__tabs">
                    {props.tabs.map((tab, index) => {
                        let className = 'entity-tab-list__header__title';
                        if (index === activeTab) {
                            className += ' ' + className + '--active';
                        }
                        return (
                            <h5
                                className={className}
                                onClick={() => setActiveTab(index)}
                                key={index}
                            >
                                {tab.title}
                            </h5>
                        );
                    })}
                </div>
                <button
                    className="entity-tab-list__header__button"
                    onClick={() => props.tabs[activeTab].onAdd()}
                >
                    Add new
                </button>
            </div>
            <ul className="entity-tab-list__list">
                {props.tabs[activeTab].items.map((item, index) => (
                    <li key={index} className="entity-tab-list__list__item">
                        <EntityListItem
                            name={item.name}
                            type={item.type}
                            amount={item.amount}
                            icon={item.icon}
                            onOpen={() => props.tabs[activeTab].onUpdate(item)}
                        />
                    </li>
                ))}
            </ul>
            {props.tabs[activeTab].items.length !== 0 && (
                <div className="entity-tab-list__footer-note">
                    Click an item to update or delete.
                </div>
            )}
        </section>
    );
}

export default EntityTabList;
