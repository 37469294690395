import { ISettingsState } from '../../models';
import {
    SET_ALL_SETTINGS,
    UPDATE_DEFAULT_MONTHLY_INCOME,
    UPDATE_TOGGLE_SETTINGS
} from '../actionTypes';

const initialState = {
    defaultMonthlyIncome: 0,
    toggles: {
        autoLogoutOnClose: false,
        dailyReminder: false,
        monthEndAlert: false
    }
} as ISettingsState;

export default function (state = initialState, action: any) {
    switch (action.type) {
        case SET_ALL_SETTINGS:
            return {
                defaultMonthlyIncome: action.settings.defaultIncome,
                toggles: {
                    dailyReminder: action.settings.dailyReminder,
                    monthEndAlert: action.settings.monthEndAlert,
                    autoLogoutOnClose: action.settings.autoLogoutOnClose
                }
            };
        case UPDATE_DEFAULT_MONTHLY_INCOME:
            return {
                ...state,
                defaultMonthlyIncome: action.defaultIncome
            };
        case UPDATE_TOGGLE_SETTINGS:
            return {
                ...state,
                toggles: action.toggles
            };
        default:
            return state;
    }
}
