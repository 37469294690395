import React from 'react';
import './UpdateOverlay.scss';
import { FaCheck, FaTrash } from 'react-icons/fa';
import API from '../../api';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentMonth } from '../../redux/selectors';
import { IUpdateOverlayProps } from './UpdateOverlay.interface';
import { Category, Entity } from '../../models';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import {
    UPDATE_EMI,
    UPDATE_EXPENSE,
    UPDATE_PAYMENT,
    DELETE_EMI,
    DELETE_EXPENSE,
    DELETE_PAYMENT,
    SET_EXPENSES
} from '../../redux/actionTypes';

function UpdateOverlay(props: IUpdateOverlayProps) {
    const [title, setTitle] = React.useState(props.entity.title);
    const [amount, setAmount] = React.useState(props.entity.amount.toString());
    const [category, setCategory] = React.useState(props.entity.category);

    const dispatch = useDispatch();

    const month = useSelector(getCurrentMonth);

    const onUpdate = async () => {
        if (amount === '') {
            return;
        }
        try {
            let dispatchAction = null;
            if (props.type === Entity.EXPENSE) {
                await API.updateExpense(props.entity.id, {
                    title,
                    category,
                    amount: parseInt(amount)
                });
                dispatchAction = UPDATE_EXPENSE;
            } else if (props.type === Entity.PAYMENT) {
                await API.updatePayment(props.entity.id, {
                    title,
                    category,
                    amount: parseInt(amount)
                });
                dispatchAction = UPDATE_PAYMENT;
            } else if (props.type === Entity.EMI) {
                await API.updateEMI(props.entity.id, {
                    title,
                    category,
                    amount: parseInt(amount)
                });
                dispatchAction = UPDATE_EMI;
            }
            if (dispatchAction) {
                dispatch({
                    type: dispatchAction,
                    id: props.entity.id,
                    data: {
                        title,
                        category,
                        amount: parseInt(amount)
                    }
                });
            }
        } catch (e) {}
        props.onCancel();
    };

    const onDelete = async () => {
        try {
            const { id } = props.entity;
            if (props.type === Entity.EXPENSE) {
                API.deleteExpense(id).then(() => {
                    dispatch({ type: DELETE_EXPENSE, id });
                });
            } else if (props.type === Entity.PAYMENT) {
                API.deletePayment(id).then(() => {
                    dispatch({ type: DELETE_PAYMENT, id });
                });
            } else if (props.type === Entity.EMI) {
                API.deleteEMI(id).then(() => {
                    dispatch({ type: DELETE_EMI, id });
                });
            }
        } catch (e) {}
        props.onCancel();
    };

    const onAddToExpense = async () => {
        try {
            const expenses = await API.addExpense(month, {
                title,
                category,
                amount: parseInt(amount),
                isEMI: props.type === Entity.EMI,
                isRepeatPayment: props.type === Entity.PAYMENT
            });
            dispatch({ type: SET_EXPENSES, expenses });
        } catch (e) {}
        props.onCancel();
    };

    return (
        <section className="update-overlay">
            <div className="update-overlay__update-entity">
                <h6 className="update-overlay__update-entity__heading">
                    {`Update ${props.type}`}
                </h6>
                <form className="update-overlay__update-entity__form">
                    <input
                        className="update-overlay__box-input"
                        value={title}
                        type="text"
                        placeholder="Title"
                        onChange={(e: any) => setTitle(e.target.value)}
                    />
                    <input
                        className="update-overlay__box-input"
                        value={amount}
                        type="number"
                        placeholder="Amount"
                        onChange={(e: any) => setAmount(e.target.value)}
                    />
                    <Dropdown
                        options={[
                            Category.ESSENTIAL,
                            Category.DISCRETIONARY,
                            Category.ENTERTAINMENT
                        ]}
                        value={category}
                        onChange={(selection) =>
                            setCategory(selection.value as Category)
                        }
                    />
                    <div className="update-overlay__buttons-container">
                        <button
                            className="update-overlay__delete-button"
                            type="button"
                            onClick={() => onDelete()}
                        >
                            <FaTrash color="#c0c0c0" />
                            <div>Delete</div>
                        </button>
                        <button
                            className="update-overlay__cancel-button"
                            type="button"
                            onClick={() => props.onCancel()}
                        >
                            <div>Cancel</div>
                        </button>
                        <button
                            className="update-overlay__save-button"
                            type="button"
                            onClick={() => onUpdate()}
                        >
                            <div>Save</div>
                            <FaCheck color="#c0c0c0" />
                        </button>
                    </div>
                    {(props.type === Entity.PAYMENT ||
                        props.type === Entity.EMI) && (
                        <button
                            className="update-overlay__add-to-expenses-button"
                            type="button"
                            onClick={() => onAddToExpense()}
                        >
                            <div>Add to expenses</div>
                        </button>
                    )}
                </form>
            </div>
        </section>
    );
}

export default UpdateOverlay;
