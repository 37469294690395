import { IMeterReadingsWithNotificationError, IMonthData } from '../models';

export function getPercent(x: number, outOf: number) {
    return parseFloat(((100 * x) / outOf).toFixed(2));
}

export function getMeterReadings(
    monthData: IMonthData
): IMeterReadingsWithNotificationError {
    if (monthData.income <= 0) {
        return {
            readings: [10, 0, 0],
            error: 'INVALID_INCOME'
        };
    }

    let error = false;
    let emiExpense = 0;
    let normalExpense = 0;

    monthData.expenses.forEach((expense) => {
        if (expense.isEMI) {
            emiExpense += expense.amount;
        } else {
            normalExpense += expense.amount;
        }
    });

    let savingsPer = 10;
    let emiPer = 0;
    let normalPer = 0;

    emiPer = (100 * emiExpense) / monthData.income;
    normalPer = (100 * normalExpense) / monthData.income;
    if (normalPer <= 70 && emiPer <= 20) {
        // emi & normal both under limits
        savingsPer = 10;
    } else if (normalPer > 70 && emiPer <= 20) {
        // emi under limit but normal out of limit
        let extra = normalPer - 70;
        normalPer = 70;
        emiPer += extra;
        if (emiPer > 20) {
            extra = emiPer - 20;
            emiPer = 20;
            savingsPer -= extra;
            if (savingsPer < 0) {
                savingsPer = 0;
                error = true;
            }
        }
    } else if (normalPer <= 70 && emiPer > 20) {
        // emi out of limit while normal under limit
        let extra = emiPer - 20;
        emiPer = 20;
        normalPer += extra;
        if (normalPer > 70) {
            extra = normalPer - 70;
            normalPer = 70;
            savingsPer -= extra;
            if (savingsPer < 0) {
                savingsPer = 0;
                error = true;
            }
        }
    } else if (normalPer > 70 && emiPer > 20) {
        // both emi & normal out of limits
        let extra = emiPer - 20;
        emiPer = 20;
        normalPer += extra;
        extra = normalPer - 70;
        normalPer = 70;
        savingsPer -= extra;
        if (savingsPer < 0) {
            savingsPer = 0;
            error = true;
        }
    }

    return {
        readings: [savingsPer, emiPer, normalPer],
        error: error ? 'MORE_THAN_100' : undefined
    };
}
