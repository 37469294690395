import APIUtil from './util';
import store from '../redux/store';
import { SET_APP_STATE } from '../redux/actionTypes';
import axios from 'axios';
import Toaster from '../utils/toastHandler';
import {
    IEntity,
    IExpense,
    IEntityUpdate,
    IMonth,
    ToggleSetting
} from '../models';

const API_BASE_PATH = process.env.REACT_APP_API_BASE_PATH;
if (!API_BASE_PATH) {
    throw new Error(
        '"REACT_APP_API_BASE_PATH" must be configured in environment.'
    );
}

async function googleOAuth(idToken: string) {
    try {
        store.dispatch({ type: SET_APP_STATE, state: 'LOADING' });
        const response = await axios.post(`${API_BASE_PATH}/auth/oauth`, {
            idToken
        });
        store.dispatch({ type: SET_APP_STATE, state: 'IDLE' });
        return response.data.jwtToken;
    } catch {
        store.dispatch({ type: SET_APP_STATE, state: 'IDLE' });
        return null;
    }
}

async function getMonthData(month: IMonth) {
    try {
        const response = await APIUtil.get(
            `${API_BASE_PATH}/month?m=${month.month}&y=${month.year}`
        );
        return response.data;
    } catch (error) {
        Toaster.toast(
            'Failed to load expenses, please refresh to try again.',
            true
        );
        throw error;
    }
}

async function addExpense(month: IMonth, data: IExpense) {
    try {
        const response = await APIUtil.post(`${API_BASE_PATH}/month/expense`, {
            month,
            title: data.title,
            amount: data.amount,
            category: data.category,
            isEMI: data.isEMI,
            isRepeatPayment: data.isRepeatPayment
        });
        return response.data;
    } catch (error) {
        Toaster.toast(
            `Failed to add expense: ${data.title}, please try again.`,
            true
        );
        throw error;
    }
}

async function updateExpense(id: number, data: IEntityUpdate) {
    try {
        return APIUtil.patch(`${API_BASE_PATH}/month/expense?id=${id}`, {
            title: data.title,
            amount: data.amount,
            category: data.category
        });
    } catch (error) {
        Toaster.toast(
            `Failed to update expense: ${data.title}, please try again.`,
            true
        );
        throw error;
    }
}

async function deleteExpense(id: number) {
    try {
        return APIUtil.del(`${API_BASE_PATH}/month/expense?id=${id}`);
    } catch (error) {
        Toaster.toast('Failed to delete expense, please try again.', true);
        throw error;
    }
}

async function getPayments() {
    try {
        const response = await APIUtil.get(`${API_BASE_PATH}/payment`);
        return response.data;
    } catch (error) {
        Toaster.toast(
            'Failed to load payments, please refresh to try again.',
            true
        );
        throw error;
    }
}

async function addPayment(data: IEntity) {
    try {
        const response = await APIUtil.post(`${API_BASE_PATH}/payment`, {
            title: data.title,
            amount: data.amount,
            category: data.category
        });
        return response.data;
    } catch (error) {
        Toaster.toast(
            `Failed to add payment: ${data.title}, please try again.`,
            true
        );
        throw error;
    }
}

async function updatePayment(id: number, data: IEntityUpdate) {
    try {
        return APIUtil.patch(`${API_BASE_PATH}/payment?id=${id}`, {
            title: data.title,
            amount: data.amount,
            category: data.category
        });
    } catch (error) {
        Toaster.toast(
            `Failed to update payment: ${data.title}, please try again.`,
            true
        );
        throw error;
    }
}

async function deletePayment(id: number) {
    try {
        return APIUtil.del(`${API_BASE_PATH}/payment?id=${id}`);
    } catch (error) {
        Toaster.toast('Failed to delete payment, please try again.', true);
        throw error;
    }
}

async function getEMIs() {
    try {
        const response = await APIUtil.get(`${API_BASE_PATH}/emi`);
        return response.data;
    } catch (error) {
        Toaster.toast(
            'Failed to load EMIs, please refresh to try again.',
            true
        );
        throw error;
    }
}

async function addEMI(data: IEntity) {
    try {
        const response = await APIUtil.post(`${API_BASE_PATH}/emi`, {
            title: data.title,
            amount: data.amount,
            category: data.category
        });
        return response.data;
    } catch (error) {
        Toaster.toast(
            `Failed to add EMI: ${data.title}, please try again.`,
            true
        );
        throw error;
    }
}

async function updateEMI(id: number, data: IEntityUpdate) {
    try {
        return APIUtil.patch(`${API_BASE_PATH}/emi?id=${id}`, {
            title: data.title,
            amount: data.amount,
            category: data.category
        });
    } catch (error) {
        Toaster.toast(
            `Failed to update EMI: ${data.title}, please try again.`,
            true
        );
        throw error;
    }
}

async function deleteEMI(id: number) {
    try {
        return APIUtil.del(`${API_BASE_PATH}/emi?id=${id}`);
    } catch (error) {
        Toaster.toast('Failed to delete EMI, please try again.', true);
        throw error;
    }
}

async function updateIncome(month: IMonth, income: number) {
    try {
        return await APIUtil.post(`${API_BASE_PATH}/month/income`, {
            month,
            income
        });
    } catch (error) {
        Toaster.toast('Failed to update income, please try again.', true);
        throw error;
    }
}

async function updateDefaultIncome(defaultIncome: number) {
    try {
        return await APIUtil.post(`${API_BASE_PATH}/user/settings`, {
            defaultIncome
        });
    } catch (error) {
        Toaster.toast(
            'Failed to update default income, please try again.',
            true
        );
        throw error;
    }
}

async function getUserSettings() {
    try {
        const response = await APIUtil.get(`${API_BASE_PATH}/user/settings`);
        return response.data;
    } catch (error) {
        Toaster.toast(
            'Failed to load settings, please refresh to try again.',
            true
        );
        throw error;
    }
}

async function updateToggleSettings(
    toggleSettings: Record<ToggleSetting, boolean>
) {
    try {
        return await APIUtil.post(
            `${API_BASE_PATH}/user/settings`,
            toggleSettings
        );
    } catch (error) {
        Toaster.toast('Failed to update setting, please try again.', true);
        throw error;
    }
}

async function requestDataExport() {
    try {
        const response = await APIUtil.get(`${API_BASE_PATH}/data/export`);
        return response.data;
    } catch (error) {
        Toaster.toast('Export request failed, please try again.', true);
        throw error;
    }
}

export default {
    googleOAuth,
    getMonthData,
    addExpense,
    updateExpense,
    deleteExpense,
    getPayments,
    addPayment,
    updatePayment,
    deletePayment,
    getEMIs,
    addEMI,
    updateEMI,
    deleteEMI,
    updateIncome,
    updateDefaultIncome,
    getUserSettings,
    updateToggleSettings,
    requestDataExport
};
