import React from 'react';
import {
    buildStyles,
    CircularProgressbarWithChildren
} from 'react-circular-progressbar';
import './MeterSet.scss';
import 'react-circular-progressbar/dist/styles.css';
import { IMeterSet } from './MeterSet.interface';

const baseMeterStyles = {
    trailColor: 'rgba(0, 0, 0, 0)',
    backgroundColor: 'rgba(63, 67, 66, 0.75)'
};

function getXFor100(x: number, relativeWith: number) {
    return (100 * x) / relativeWith;
}

function MeterSet(props: IMeterSet) {
    const savings = getXFor100(props.savings, 10);
    const emi = getXFor100(props.emi, 20);
    const expense = getXFor100(props.expense, 70);

    return (
        <section className="meter-set">
            <div className="meter-set__meter">
                <CircularProgressbarWithChildren
                    background
                    backgroundPadding={4}
                    value={savings}
                    strokeWidth={5}
                    styles={buildStyles({
                        ...baseMeterStyles,
                        pathColor: '#ffa400'
                    })}
                >
                    <span className="meter-set__meter__text meter-set__meter__text--orange">
                        {props.savings.toFixed(0) + '%'}
                        <br />
                        saved
                    </span>
                </CircularProgressbarWithChildren>
            </div>
            <div className="meter-set__meter">
                <CircularProgressbarWithChildren
                    background
                    backgroundPadding={4}
                    value={emi}
                    strokeWidth={5}
                    styles={buildStyles({
                        ...baseMeterStyles,
                        pathColor: '#25d6e3'
                    })}
                >
                    <span className="meter-set__meter__text meter-set__meter__text--turquoise">
                        {props.emi.toFixed(0) + '%'}
                        <br />
                        used out of
                        <br />
                        20%
                    </span>
                </CircularProgressbarWithChildren>
            </div>
            <div className="meter-set__meter">
                <CircularProgressbarWithChildren
                    background
                    backgroundPadding={4}
                    value={expense}
                    strokeWidth={5}
                    styles={buildStyles({
                        ...baseMeterStyles,
                        pathColor: '#ffffff'
                    })}
                >
                    <span className="meter-set__meter__text meter-set__meter__text--white">
                        {props.expense.toFixed(0) + '%'}
                        <br />
                        used out of
                        <br />
                        70%
                    </span>
                </CircularProgressbarWithChildren>
            </div>
        </section>
    );
}

export default MeterSet;
