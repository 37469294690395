import { FaCheck, FaTimes } from 'react-icons/fa';
import React from 'react';
import './MonthPickerOverlay.scss';
import MonthPicker from '../MonthPicker/MonthPicker';
import { IMonthPickerOverlayProps } from './MonthPicker.interface';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentMonth } from '../../redux/selectors';
import { SET_MONTH } from '../../redux/actionTypes';

function getThisMonth() {
    const today = new Date();
    return {
        month: today.getMonth(),
        year: today.getFullYear()
    };
}

function MonthPickerOverlay(props: IMonthPickerOverlayProps) {
    const savedMonth = useSelector(getCurrentMonth);

    const dispatch = useDispatch();

    const [selection, setSelection] = React.useState(savedMonth);

    return (
        <section className="month-picker-overlay">
            <div className="month-picker-overlay__picker">
                <h6 className="month-picker-overlay__picker__heading">
                    Choose month
                </h6>
                <MonthPicker
                    initialMonth={selection}
                    minMonthLimit={{ year: 2000, month: 0 }}
                    maxMonthLimit={getThisMonth()}
                    onChange={(month) => setSelection(month)}
                />
                <div>
                    <div className="month-picker-overlay__buttons-container">
                        <button
                            className="month-picker-overlay__cancel-button"
                            type="button"
                            onClick={() => props.onCancel()}
                        >
                            <FaTimes color="#c0c0c0" />
                            <div>Cancel</div>
                        </button>
                        <button
                            className="month-picker-overlay__save-button"
                            type="button"
                            onClick={() => {
                                dispatch({
                                    type: SET_MONTH,
                                    month: selection.month,
                                    year: selection.year
                                });
                                props.onCancel();
                            }}
                        >
                            <div>Save</div>
                            <FaCheck color="#c0c0c0" />
                        </button>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default MonthPickerOverlay;
