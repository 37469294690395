import store from '../redux/store';
import { SET_APP_STATE } from '../redux/actionTypes';
import axios from 'axios';
import LocalStorageMap from '../constants/localStorageMap';

function getAuthHeader() {
    const jwtToken = localStorage.getItem(LocalStorageMap.JWT_TOKEN);
    return `Bearer ${jwtToken}`;
}

async function get(url: string) {
    try {
        store.dispatch({ type: SET_APP_STATE, state: 'LOADING' });
        const response = await axios.get(url, {
            headers: {
                authorization: getAuthHeader()
            }
        });
        store.dispatch({ type: SET_APP_STATE, state: 'IDLE' });
        return response;
    } catch (e) {
        store.dispatch({ type: SET_APP_STATE, state: 'IDLE' });
        throw e;
    }
}

async function post(url: string, body: any) {
    try {
        store.dispatch({ type: SET_APP_STATE, state: 'LOADING' });
        const response = await axios.post(url, body, {
            headers: {
                authorization: getAuthHeader()
            }
        });
        store.dispatch({ type: SET_APP_STATE, state: 'IDLE' });
        return response;
    } catch (e) {
        store.dispatch({ type: SET_APP_STATE, state: 'IDLE' });
        throw e;
    }
}

async function patch(url: string, body: any) {
    try {
        store.dispatch({ type: SET_APP_STATE, state: 'LOADING' });
        const response = await axios.patch(url, body, {
            headers: {
                authorization: getAuthHeader()
            }
        });
        store.dispatch({ type: SET_APP_STATE, state: 'IDLE' });
        return response;
    } catch (e) {
        store.dispatch({ type: SET_APP_STATE, state: 'IDLE' });
        throw e;
    }
}

async function del(url: string) {
    try {
        store.dispatch({ type: SET_APP_STATE, state: 'LOADING' });
        const response = await axios.delete(url, {
            headers: {
                authorization: getAuthHeader()
            }
        });
        store.dispatch({ type: SET_APP_STATE, state: 'IDLE' });
        return response;
    } catch (e) {
        store.dispatch({ type: SET_APP_STATE, state: 'IDLE' });
        throw e;
    }
}

export default {
    get,
    post,
    patch,
    del
};
