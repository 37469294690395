import { IEntityListItemProps } from './EntityListItem.interface';
import React from 'react';
import './EntityListItem.scss';

function EntityListItem(props: IEntityListItemProps) {
    return (
        <div className="list-item" onClick={props.onOpen}>
            <div className="list-item__content">
                <div>{props.name}</div>
                <span>{props.type}</span>
            </div>
            <div className="list-item__amount">{props.amount}</div>
        </div>
    );
}

export default EntityListItem;
