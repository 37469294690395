import React from 'react';
import './LoginForm.scss';
import GoogleLogin from '../GoogleLogin/GoogleLogin';
import { ILoginForm } from './LoginForm.interface';

function LoginForm(props: ILoginForm) {
    const [email, setEmail] = React.useState('');
    const [, setEmailError] = React.useState(' ');
    const [password, setPassword] = React.useState('');
    const [, setPasswordError] = React.useState(' ');

    const onLogin = () => {
        if (
            !/^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/.test(
                email
            )
        ) {
            setEmailError('Invalid email address.');
            return;
        }
        setEmailError('');
        if (
            !/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(
                password
            )
        ) {
            setPasswordError("Password doesn't meet all criterion.");
            return;
        }
        setPasswordError('');
        props.onLogin({ email, password });
    };

    const onSignup = (event: any) => {
        event.preventDefault();
        props.onSignup();
    };

    return (
        <div className="login-form">
            <label className="login-form__label">Email ID:</label>
            <input
                className="login-form__input"
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
            />
            <label className="login-form__label">Password:</label>
            <input
                className="login-form__input"
                type="password"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
            />
            <button className="login-form__button" onClick={onLogin}>
                LOGIN
            </button>
            <a className="login-form__label-link" href="#" onClick={onSignup}>
                New here? Signup instead.
            </a>
            <hr className="login-form__hr" />
            <div className="login-form__google">
                <GoogleLogin
                    text="signin_with"
                    onComplete={props.onSignInWithGoogle}
                />
            </div>
        </div>
    );
}

export default LoginForm;
