import { combineReducers } from 'redux';
import app from './app';
import dashboard from './dashboard';
import { IDashboardState, ISettingsState } from '../../models';
import settings from './settings';

export interface IStoreState {
    app: any;
    dashboard: IDashboardState;
    settings: ISettingsState;
}

export default combineReducers<IStoreState>({
    app,
    dashboard,
    settings
});
