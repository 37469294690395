import React from 'react';
import './SplashLoader.scss';
import logo from '../../assets/images/logo_1536.png';

function SplashLoader() {
    return (
        <div className="splash-loader">
            <div className="splash-loader__sea">
                <div className="splash-loader__wave splash-loader__wave--outer" />
                <div className="splash-loader__wave splash-loader__wave--inner" />
            </div>
            <img src={logo} alt="Pecunry logo" />
        </div>
    );
}

export default SplashLoader;
