export enum EntityIcon {
    loan = 'loan',
    repeat = 'repeat',
    none = 'none'
}

export enum Category {
    ESSENTIAL = 'Essential',
    DISCRETIONARY = 'Discretionary',
    ENTERTAINMENT = 'Entertainment'
}

export enum Entity {
    EXPENSE = 'Expense',
    PAYMENT = 'Payment',
    EMI = 'EMI'
}

export enum ToggleSetting {
    DAILY_REMINDER = 'dailyReminder',
    MONTH_END_ALERT = 'monthEndAlert',
    AUTO_LOGOUT_ON_CLOSE = 'autoLogoutOnClose'
}

export interface IMonth {
    month: number;
    year: number;
}

export interface IEntity {
    title: string;
    amount: number;
    category: Category;
}

export interface IEntityDB extends IEntity {
    id: number;
}

export interface IEntityUpdate {
    title: string;
    amount: number;
    category: Category;
}

export interface IExpense {
    title: string;
    amount: number;
    category: Category;
    isEMI: boolean;
    isRepeatPayment: boolean;
}

export interface IExpenseDB extends IExpense {
    id: number;
}

export interface IMonthData {
    income: number;
    expenses: IExpenseDB[];
}

export type IMeterReadings = number[];

export type IMeterReadingsWithNotificationError = {
    readings: IMeterReadings;
    error?: 'INVALID_INCOME' | 'MORE_THAN_100';
};

export interface IDashboardState {
    currentMonth: number;
    currentYear: number;
    data: {
        month: IMonthData;
        payments: IEntityDB[];
        emis: IEntityDB[];
    };
}

export interface ISettingsState {
    defaultMonthlyIncome: number;
    toggles: {
        autoLogoutOnClose: boolean;
        dailyReminder: boolean;
        monthEndAlert: boolean;
    };
}

export interface ITokenInfo {
    email: string;
    first_name: string;
    last_name: string;
    username: string;
}
