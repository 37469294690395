import {
    SET_MONTH,
    SET_MONTH_DATA,
    SET_EXPENSES,
    UPDATE_EXPENSE,
    DELETE_EXPENSE,
    SET_EMIS,
    SET_PAYMENTS,
    DELETE_PAYMENT,
    DELETE_EMI,
    SET_MONTH_INCOME,
    UPDATE_PAYMENT,
    UPDATE_EMI
} from '../actionTypes';
import { IDashboardState } from '../../models';

const today = new Date();
const initialState = {
    currentMonth: today.getMonth(),
    currentYear: today.getFullYear(),
    data: {
        month: {
            income: 1,
            expenses: []
        },
        payments: [],
        emis: []
    }
} as IDashboardState;

export default function (state = initialState, action: any) {
    switch (action.type) {
        case SET_MONTH:
            return {
                ...state,
                currentMonth: action.month,
                currentYear: action.year
            };
        case SET_MONTH_DATA:
            return {
                ...state,
                data: {
                    ...state.data,
                    month: action.data
                }
            };
        case SET_MONTH_INCOME:
            return {
                ...state,
                data: {
                    ...state.data,
                    month: {
                        ...state.data.month,
                        income: action.income
                    }
                }
            };
        case SET_EXPENSES:
            return {
                ...state,
                data: {
                    ...state.data,
                    month: {
                        ...state.data.month,
                        expenses: action.expenses
                    }
                }
            };
        case UPDATE_EXPENSE:
            return {
                ...state,
                data: {
                    ...state.data,
                    month: {
                        ...state.data.month,
                        expenses: state.data.month.expenses.map((e) => {
                            if (e.id === action.id) {
                                e.title = action.data.title;
                                e.amount = action.data.amount;
                                e.category = action.data.category;
                            }
                            return e;
                        })
                    }
                }
            };
        case DELETE_EXPENSE:
            return {
                ...state,
                data: {
                    ...state.data,
                    month: {
                        ...state.data.month,
                        expenses: state.data.month.expenses.filter((e) => {
                            return e.id !== action.id;
                        })
                    }
                }
            };
        case SET_PAYMENTS:
            return {
                ...state,
                data: {
                    ...state.data,
                    payments: action.payments
                }
            };
        case UPDATE_PAYMENT:
            return {
                ...state,
                data: {
                    ...state.data,
                    payments: state.data.payments.map((p) => {
                        if (p.id === action.id) {
                            p.title = action.data.title;
                            p.amount = action.data.amount;
                            p.category = action.data.category;
                        }
                        return p;
                    })
                }
            };
        case DELETE_PAYMENT:
            return {
                ...state,
                data: {
                    ...state.data,
                    payments: state.data.payments.filter((p) => {
                        return p.id !== action.id;
                    })
                }
            };
        case SET_EMIS:
            return {
                ...state,
                data: {
                    ...state.data,
                    emis: action.emis
                }
            };
        case UPDATE_EMI:
            return {
                ...state,
                data: {
                    ...state.data,
                    emis: state.data.emis.map((e) => {
                        if (e.id === action.id) {
                            e.title = action.data.title;
                            e.amount = action.data.amount;
                            e.category = action.data.category;
                        }
                        return e;
                    })
                }
            };
        case DELETE_EMI:
            return {
                ...state,
                data: {
                    ...state.data,
                    emis: state.data.emis.filter((e) => {
                        return e.id !== action.id;
                    })
                }
            };
        default:
            return state;
    }
}
