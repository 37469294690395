import './MonthPicker.scss';
import React from 'react';
import { MONTHS } from '../../constants/static';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import { IMonthPickerProps } from './MonthPicker.interface';
import { IMonth } from '../../models';
import classNames from 'classnames';

function isPrevMonth(
    monthToCheck: IMonth,
    monthToCheckWith: IMonth | undefined
) {
    if (!monthToCheckWith) {
        return false;
    }

    if (monthToCheck.year < monthToCheckWith.year) {
        return true;
    }
    if (monthToCheck.year > monthToCheckWith.year) {
        return false;
    }

    return monthToCheck.month < monthToCheckWith.month;
}

function isLaterMonth(
    monthToCheck: IMonth,
    monthToCheckWith: IMonth | undefined
) {
    if (!monthToCheckWith) {
        return false;
    }

    if (monthToCheck.year > monthToCheckWith.year) {
        return true;
    }
    if (monthToCheck.year < monthToCheckWith.year) {
        return false;
    }

    return monthToCheck.month > monthToCheckWith.month;
}

function MonthPicker(props: IMonthPickerProps) {
    const [chosenMonth, setChosenMonth] = React.useState(
        props.initialMonth.month
    );
    const [chosenYear, setChosenYear] = React.useState(props.initialMonth.year);
    const [year, setYear] = React.useState(props.initialMonth.year);

    const isMonthOutOfLimits = (monthToCheck: IMonth) => {
        return (
            isPrevMonth(monthToCheck, props.minMonthLimit) ||
            isLaterMonth(monthToCheck, props.maxMonthLimit)
        );
    };

    return (
        <div className="month-picker">
            <div className="month-picker__header">
                <button onClick={() => setYear(year - 1)}>
                    <FaAngleLeft color="#c0c0c0" />
                </button>
                <h6>{year}</h6>
                <button onClick={() => setYear(year + 1)}>
                    <FaAngleRight color="#c0c0c0" />
                </button>
            </div>
            <div className="month-picker__calendar">
                {MONTHS.map((curMonth, index) => {
                    const disabled = isMonthOutOfLimits({
                        month: index,
                        year
                    });
                    return (
                        <button
                            disabled={disabled}
                            className={classNames({
                                'calendar-btn-selected':
                                    index === chosenMonth && year === chosenYear
                            })}
                            onClick={() => {
                                if (!disabled) {
                                    setChosenMonth(index);
                                    setChosenYear(year);
                                    props.onChange({
                                        month: index,
                                        year
                                    });
                                }
                            }}
                        >
                            {curMonth}
                        </button>
                    );
                })}
            </div>
        </div>
    );
}

export default MonthPicker;
