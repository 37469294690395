import { useSnackbar, WithSnackbarProps } from 'notistack';
import React from 'react';
import { Button, Slide } from '@material-ui/core';

function TransitionUp(props: any) {
    return <Slide {...props} direction="up" />;
}

let useSnackbarRef: WithSnackbarProps;
export const Toaster: React.FC = () => {
    useSnackbarRef = useSnackbar();
    return null;
};

export default {
    toast(msg: string, dismissable = false) {
        useSnackbarRef.enqueueSnackbar(msg, {
            anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
            action: dismissable
                ? (key) => (
                      <Button
                          onClick={() => useSnackbarRef.closeSnackbar(key)}
                          style={{
                              color: 'rgb(211, 47, 47)'
                          }}
                      >
                          Dismiss
                      </Button>
                  )
                : undefined,
            TransitionComponent: TransitionUp
        });
    }
};
