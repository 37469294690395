import { SET_APP_STATE } from '../actionTypes';

const initialState = {
    state: 'BOOTING'
};

export default function (state = initialState, action: any) {
    switch (action.type) {
        case SET_APP_STATE: {
            return {
                ...state,
                state: action.state
            };
        }
        default:
            return state;
    }
}
