import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './App.scss';
import SplashLoader from './components/SplashLoader/SplashLoader';
import { getAppState } from './redux/selectors';
import { SET_APP_STATE } from './redux/actionTypes';
import OverlayLoader from './components/OverlayLoader/OverlayLoader';
import { Router, useNavigate } from '@reach/router';
import AuthPage from './components/AuthPage/AuthPage';
import LocalStorageMap from './constants/localStorageMap';
import Routes from './constants/Routes';
import { SnackbarProvider } from 'notistack';
import AppPage from './components/AppPage/AppPage';
import { Toaster } from './utils/toastHandler';

/*
    z-indexes:
        1000 - Splash loader
         900 - Overlay loader
         800 - Full screen modals
         700 - Drawer
         650 - Navbar
         600 - In-screen modals
         0 - Content
*/

function App() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const appState = useSelector(getAppState);

    React.useEffect(() => {
        const jwtToken = localStorage.getItem(LocalStorageMap.JWT_TOKEN);
        navigate(jwtToken ? Routes.HOME_PAGE : Routes.AUTH_PAGE);
        dispatch({ type: SET_APP_STATE, state: 'IDLE' });
    }, [dispatch, navigate]);

    return (
        <section>
            <SnackbarProvider maxSnack={3}>
                <Toaster />
                {appState !== 'BOOTING' && (
                    <section>
                        <Router>
                            <AuthPage path="auth" />
                            <AppPage path="app/*" />
                        </Router>
                    </section>
                )}
                {appState === 'LOADING' && <OverlayLoader />}
                {appState === 'BOOTING' && <SplashLoader />}
            </SnackbarProvider>
        </section>
    );
}

export default App;
