import React from 'react';
import { RouteComponentProps } from '@reach/router';
import './SettingsPage.scss';
import 'react-toggle/style.css';
import Toggle from 'react-toggle';
import API from '../../api';
import {
    SET_ALL_SETTINGS,
    UPDATE_DEFAULT_MONTHLY_INCOME,
    UPDATE_TOGGLE_SETTINGS
} from '../../redux/actionTypes';
import { useDispatch, useSelector } from 'react-redux';
import { getSettings } from '../../redux/selectors';
import { ToggleSetting } from '../../models';

function SettingsPage(props: RouteComponentProps) {
    const userSettings = useSelector(getSettings);

    const dispatch = useDispatch();

    const [defaultIncome, setDefaultIncome] = React.useState(
        userSettings.defaultMonthlyIncome
    );
    const [defaultIncomeDisabled, setDefaultIncomeDisabled] =
        React.useState(false);

    const onDefaultIncomeChange = (event: any) => {
        setDefaultIncome(event.target.value);
    };

    const updateDefaultIncome = () => {
        if (
            userSettings.defaultMonthlyIncome === defaultIncome ||
            `${defaultIncome}` === ''
        ) {
            setDefaultIncome(userSettings.defaultMonthlyIncome);
            return;
        }

        setDefaultIncomeDisabled(true);
        API.updateDefaultIncome(defaultIncome).then(() => {
            dispatch({ type: UPDATE_DEFAULT_MONTHLY_INCOME, defaultIncome });
            setDefaultIncomeDisabled(false);
        });
    };

    const updateToggleSettings = (setting: ToggleSetting) => {
        const newToggles = {
            ...userSettings.toggles,
            [setting]: !userSettings.toggles[setting]
        };
        API.updateToggleSettings(newToggles).then(() => {
            dispatch({ type: UPDATE_TOGGLE_SETTINGS, toggles: newToggles });
        });
    };

    React.useEffect(() => {
        document.title = 'Pecunry | Settings';

        API.getUserSettings().then((settings) => {
            dispatch({ type: SET_ALL_SETTINGS, settings });
        });
    }, []);

    React.useEffect(() => {
        setDefaultIncome(userSettings.defaultMonthlyIncome);
        setDefaultIncomeDisabled(false);
    }, [userSettings]);

    return (
        <div className="settings-page-container">
            <section className="settings-page">
                <div className="settings-page__header">
                    <div className="settings-page__header__dmi">
                        <label className="settings-page__header__dmi__label">
                            Default monthly income:
                        </label>
                        <input
                            className="settings-page__box-input"
                            value={defaultIncome}
                            type="number"
                            onChange={onDefaultIncomeChange}
                            onBlur={updateDefaultIncome}
                            disabled={defaultIncomeDisabled}
                        />
                    </div>
                </div>
                <div className="settings-page__toggles">
                    <div className="settings-page__toggles__description-toggle">
                        <div className="settings-page__toggles__description-toggle__title">
                            <h6>Daily reminder</h6>
                            <Toggle
                                checked={userSettings.toggles.dailyReminder}
                                icons={false}
                                onChange={() =>
                                    updateToggleSettings(
                                        ToggleSetting.DAILY_REMINDER
                                    )
                                }
                                className="settings-page__toggle"
                            />
                        </div>
                        <p>
                            Daily 10pm reminder for you to record the expenses
                            you made throughout the day.
                        </p>
                    </div>
                    <div className="settings-page__toggles__description-toggle">
                        <div className="settings-page__toggles__description-toggle__title">
                            <h6>Month-end alert</h6>
                            <Toggle
                                checked={userSettings.toggles.monthEndAlert}
                                icons={false}
                                onChange={() =>
                                    updateToggleSettings(
                                        ToggleSetting.MONTH_END_ALERT
                                    )
                                }
                                className="settings-page__toggle"
                            />
                        </div>
                        <p>
                            Month end alert on 25th of each month to record all
                            pending expenses & update your income.
                        </p>
                    </div>
                    <div className="settings-page__toggles__description-toggle">
                        <div className="settings-page__toggles__description-toggle__title">
                            <h6>Auto logout on close</h6>
                            <Toggle
                                checked={userSettings.toggles.autoLogoutOnClose}
                                icons={false}
                                onChange={() =>
                                    updateToggleSettings(
                                        ToggleSetting.AUTO_LOGOUT_ON_CLOSE
                                    )
                                }
                                className="settings-page__toggle"
                            />
                        </div>
                        <p>Logout automatically when you close the page.</p>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default SettingsPage;
